<template>
	<h1>{{ $t('main.name') }}</h1>
	<p>{{ $t('home.welcome_message') }}</p>
	<p>{{ $t('home.instructions.description') }}</p>
	<ol>
		<li>{{ $t('home.instructions.step_1') }}</li>
		<li>{{ $t('home.instructions.step_2') }}</li>
	</ol>
	<CreateRoom />
</template>

<script>
import CreateRoom from "@/application/views/home/components/CreateRoom";

export default {
	name: 'HomePage',
	components: {
		CreateRoom
	}
}
</script>