import {RSocketClient} from "rsocket-core";
import {IdentitySerializer, JsonSerializer} from "rsocket-core/build/RSocketSerialization";
import {metadataRoute} from "@/infrastructure/rsocket/util";
import RSocketWebSocketClient from "rsocket-websocket-client";

const createClient = (url, sessionId) => {
	return new RSocketClient({
		serializers: {data: JsonSerializer, metadata: IdentitySerializer},
		setup: {
			keepAlive: 60000,
			lifetime: 180000,
			dataMimeType: 'application/json',
			metadataMimeType: 'message/x.rsocket.routing.v0',
			payload: {metadata: metadataRoute('setup'), data: {id: sessionId}}
		},
		transport: new RSocketWebSocketClient({url})
	});
}

const connect = (url, sessionId, callback) => {
	console.debug("Connecting to", url);

	createClient(url, sessionId)
		.connect()
		.subscribe(callback);
};

export {connect}