export default {
	en: {
		main: {
			name: "PlanPok",
			error: {
				connection: "Cannot connect to server, have you tried to turn it off and on again?"
			}
		},
		navigation_bar: {
			home: "Home"
		},
		footer: {
			year: "2022",
			twitter: "https://twitter.com/planpok",
			support_welcome: "Your support is welcome"
		},
		home: {
			create_room: "Create room",
			welcome_message: "Estimate with a distributed team without hassle.",
			instructions: {
				description: "Two steps:",
				step_1: "Create a room, you will be the owner/moderator",
				step_2: "Share the room URL with team members",
			},
			error: {
				cannot_create_room: "Sorry but cannot create room, please try again later"
			}
		},
		languages: {
			en: "English",
			es: "Spanish"
		},
		room: {
			name: "Room {name}",
			copy_clipboard_hint: "Click to copy to clipboard",
			copied_to_clipboard: "URL copied to clipboard",
			active: "I want to vote",
			viewer: "Just watching around",
			reset_votes: "Reset votes",
			reveal_votes: "Reveal votes"
		},
		room_settings: {
			button_hint: "Click to show/hide room settings",
			reveal_mode: {
				label: "Show result manually",
				help: "Show votes when moderator decides to. If set to false, it is automatically shown when everyone has voted"
			},
			reveal: {
			},
			error: {
				server: "Cannot load settings"
			},
		},
		reset: {
			error: {
				forbidden: "You are not allowed to reset votes"
			}
		},
		reveal: {
			error: {
				forbidden: "You are not allowed to reveal votes"
			}
		},
		vote: {
			error: {
				reveal_enabled: "You cannot vote when votes are visible"
			}
		}
	},
	es: {
		main: {
			name: "PlanPok",
			error: {
				connection: "No puedo conectar con el servidor, ¿has probado a apagar y encenderlo de nuevo?"
			}
		},
		navigation_bar: {
			home: "Inicio"
		},
		footer: {
			year: "2022",
			twitter: "https://twitter.com/planpok",
			support_welcome: "Todo apoyo es bienvenido"
		},
		home: {
			create_room: "Crear sala",
			welcome_message: "Estima con tu equipo sin complicaciones.",
			instructions: {
				description: "Dos pasos:",
				step_1: "Crea una sala, serás el propietario/moderador",
				step_2: "Comparte la URL de la sala con tus compañeros",
			},
			error: {
				cannot_create_room: "Lo siento pero no se puede crear la sala, prueba un poco más tarde"
			}
		},
		languages: {
			en: "Inglés",
			es: "Español"
		},
		room: {
			name: "Sala {name}",
			copy_clipboard_hint: "Haz clic para copiar al portapapales",
			copied_to_clipboard: "URL copiada al portapapeles",
			active: "Quiero votar",
			viewer: "Sólo estoy mirando",
			reset_votes: "Limpiar votación",
			reveal_votes: "Descubrir votos"
		},
		room_settings: {
			button_hint: "Pulsa para mostrar/ocultar los ajustes de la sala",
			reveal_mode: {
				label: "Mostrar resultado manualmente",
				help: "Mostrar resultado cuando lo decida el moderador. Si está desactivado se mostrará cuando todos hayan votado"
			},
			error: {
				server: "No ha sido posible recuperar la configuración"
			},
		},
		reset: {
			error: {
				forbidden: "No tienes permisos para limpiar la votación"
			}
		},
		reveal: {
			error: {
				forbidden: "No tienes permisos para mostrar la votación"
			}
		},
		vote: {
			error: {
				reveal_enabled: "No puedes votar cuando los votos están visibles"
			}
		}
	}
}