import {createApp} from 'vue'
import Vue3Storage, {StorageType} from "vue3-storage";
import router from "@/application/router";
import App from '@/App.vue'
import {createI18n} from 'vue-i18n'
import messages from "@/application/i18n/messages";
import Toast from "vue-toastification";
import Select2 from 'vue3-select2-component';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import "vue-toastification/dist/index.css";

const i18n = createI18n({
	locale: "en",
	fallbackLocale: "en",
	messages: messages
});

createApp(App)
	.use(router)
	.use(i18n)
	.use(Toast)
	.use(Vue3Storage, {namespace: "planpok_", storage: StorageType.Session})
	.component('Select2', Select2)
	.mount('#app');

import "bootstrap/dist/js/bootstrap.js"