import {useToast} from "vue-toastification";

const options = {
	position: "top-right",
	timeout: 2000,
	closeOnClick: true,
	closeButton: "button",
	hideProgressBar: true,
	showCloseButtonOnHover: true,
	icon: true
};
const success = msg => useToast().success(msg, options)
const error = msg => useToast().error(msg, options)

export { success, error }