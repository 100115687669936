import {createRouter, createWebHistory} from "vue-router";
import HomePage from "@/application/views/home/HomePage";

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: [
		{path: '/', name: 'home', component: HomePage},
		{path: '/room/:roomId', name: 'room', component: () => import('@/application/views/room/VotingRoom')}
	]
});

export default router