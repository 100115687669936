import {connect} from "@/infrastructure/rsocket/server";
import getEnv from "@/application/util/env";
import {metadataRoute} from "@/infrastructure/rsocket/util";

const create = (sessionId, callback) => {
	connect(getEnv('VUE_APP_WEBSOCKET_URL'), sessionId, {
		onComplete: socket => {
			socket.requestResponse({
				data: {sessionId: sessionId},
				metadata: metadataRoute('/room/create'),
			}).subscribe({
				onComplete: response => {
					socket.close();

					console.debug("Created room " + response.data.id);

					if(callback !== undefined && typeof callback.onSuccess === "function") {
						callback.onSuccess(response.data.id);
					}
				},
				onError: err => {
					socket.close();

					console.error("Cannot create room", err);

					if(callback !== undefined && typeof callback.onFailure === "function") {
						callback.onFailure(err);
					}
				}
			});
		},
		onError: message => {
			console.error("Connection error", message)
			if(callback !== undefined && typeof callback.onError === "function") {
				callback.onError(message);
			}
		}
	});
};

export {create};