<template>
	<button class="create-room" @click="createRoom">{{ $t('home.create_room') }}</button>
</template>

<script>
import {create} from "@/domain/service/room_service";
import {error} from "@/application/ui/toast";

export default {
	name: 'CreateRoom',
	data() {
		return {
			socket: null,
			sessionId: this.$storage.getStorageSync("session_id")
		};
	},
	methods: {
		createRoom() {
			create(this.sessionId, {
				onSuccess: roomId => this.$router.push({name: 'room', params: {roomId: roomId}}),
				onFailure: () => error(this.$i18n.t("home.error.cannot_create_room")),
				onError: message => error(this.$i18n.t('main.error.connection') + " " + message)
			});
		}
	}
};
</script>

<style lang="css" scoped>
button.create-room {
	border-color: #0d4122;
	color: #fff;
	background-color: #1d7c44;
	padding: 0.4rem 0.8rem;
}
</style>