<template>
	<div id="header">
		<div class="home">
			<router-link :to="{ name: 'home' }"><img src="/logo.png" :alt="$t('navigation_bar.home')"/></router-link>
		</div>
		<div class="language-switcher">
			<Select2 v-model="locale.selected" :options="locale.available" @select="updateLocale($event['id'])"/>
		</div>
	</div>
	<div class="container">
		<router-view></router-view>
	</div>
	<div id="footer">{{ $t('footer.year') }} <a :href="$t('footer.twitter')" target="_blank"><em class="bi-twitter"></em></a> {{ $t('footer.support_welcome') }}
		<a href="https://www.buymeacoffee.com/tatai" target="_blank"><img
			src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee"
			style="height: 1.5rem;"></a></div>
</template>

<script>
import Select2 from 'vue3-select2-component';
import {uuid} from "vue-uuid";

export default {
	name: 'App',
	components: {
		Select2
	},
	data() {
		return {
			locale: {
				selected: this.$i18n.locale,
				available: this.$i18n.availableLocales.map(locale => ({
					id: locale,
					text: this.$i18n.t('languages.' + locale)
				})),
			}
		}
	},
	methods: {
		updateLocale(locale) {
			this.$i18n.locale = locale;
		}
	},
	mounted() {
		if(!this.$storage.hasKey("session_id")) {
			this.$storage.setStorage({key: "session_id", data: uuid.v4()})
		}
	},
	beforeUnmount() {
		this.$storage.removeStorageSync("session_id");
	}
}
</script>

<style lang="css">
:root body {
	--bs-body-bg: #d3fce2;
	--bs-body-bg-rgb: rgb(211, 252, 226);
	--bs-body-font-family: 'Raleway', sans-serif;
	--bs-body-color: #0d4122;
	--bs-primary: #0d4122;
	--bs-secondary: #1d7c44;
}

body {
	padding-bottom: 3rem;
}

#header {
	height: 3rem;
	margin-bottom: 1rem;
	padding: 0 1rem;
}

#header div {
	height: 3rem;
	line-height: 3.5rem;
}

#header .home {
	display: inline;
	font-size: 2rem;
}

#header .home img {
	height: 2rem;
	border: 0;
}

#header .language-switcher {
	display: inline;
	float: right;
	min-width: 8rem;
}

#footer {
	position: fixed;
	height: 1.7rem;
	bottom: 0;
	width: 100%;
	background-color: var(--bs-primary);
	color: #fff;
	text-align: center;
	transition: bottom 0.5s ease-out;
}

#footer:hover {
	bottom: 0;
}

#footer a {
	color: #fff;
	font-weight: bold;
}

.help-tooltip-button {
	color: var(--bs-primary);
}
</style>
